import React from 'react';
import { TaskList } from '@sharefiledev/icons';
import type { PiletApi } from '@sharefiledev/sharefile-appshell';
import { AsyncSelectFolderModal, taskAggregatorModals } from './overlays';
import { AsyncBlockDevelopmentPage } from './Pages';
import {
	AsyncAllFilesBlock,
	AsyncClientTaskListBlock,
	AsyncExportFilesBlock,
	AsyncTaskListBlock,
	taskAggregatorBlockTypes,
} from './SolutionViewEngine/Blocks';
import { configureHttp } from './TaskAggregatorHttp';
import { TranslatedString } from './utils/TranslatedString';

export function setup(app: PiletApi) {
	app.registerExtension(taskAggregatorBlockTypes.clientTaskListBlock, ({ params }) => (
		<AsyncClientTaskListBlock piletApi={app} {...params} />
	));

	app.registerExtension(taskAggregatorBlockTypes.taskListBlock, ({ params }) => (
		<AsyncTaskListBlock piletApi={app} {...params} />
	));

	app.registerExtension(taskAggregatorBlockTypes.saveFilesToFolder, ({ params }) => (
		<AsyncExportFilesBlock piletApi={app} {...params} />
	));

	app.registerModal(
		taskAggregatorModals.selectFolder,
		({ ...props }) => <AsyncSelectFolderModal {...props} piletApi={app} />,
		{
			modalProps: {
				title: <TranslatedString localizationKey="selectFolderModal.saveAllToFolder" />,
				destroyOnClose: true,
				footer: null,
			},
		}
	);

	app.registerExtension(taskAggregatorBlockTypes.allFilesBlock, ({ params }) => (
		<AsyncAllFilesBlock piletApi={app} {...params} />
	));

	const smokeTestCustomers = [
		'pilet-tests.sharefiletest.com',
		'pilet-tests.sharefilestaging.com',
		'pilet-tests.sharefile.com',
	];

	if (
		process.env.NODE_ENV === 'development' ||
		smokeTestCustomers.includes(location.hostname)
	) {
		app.registerPage('/task-aggregator-block-development', () => (
			<AsyncBlockDevelopmentPage piletApi={app} />
		));

		app.sf.registerLeftNavComponent({
			href: '/task-aggregator-block-development',
			title: () => 'Task Aggregator Block Dev',
			icon: TaskList,
			weight: 50,
		});

		if (typeof app.meta.config.apiOrigin === 'string') {
			configureHttp(config => (config.origin = app.meta.config.apiOrigin));
		}
	}
}
