import { createScopedInstanceAndLoader } from '@citrite/translate';

const instance = createScopedInstanceAndLoader({
	namespace: 'task-aggregator-pilet',
	localesLoaderMap: {
		de: () => import('./locales/de.json'),
		en: () => import('./locales/en.json'),
		es: () => import('./locales/es.json'),
		fr: () => import('./locales/fr.json'),
		it: () => import('./locales/it.json'),
		ja: () => import('./locales/ja.json'),
		nl: () => import('./locales/it.json'),
		'pt-BR': () => import('./locales/pt-BR.json'),
	},
});

export const { t, LocalizationLoader } = instance;
