import React from 'react';
import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { BlockParams } from '@sharefiledev/solution-view-engine';
import { Skeleton, Spin } from 'antd';
import { StringOrStringArray, TaskCategory, TaskType } from '../../types';
import { ExportFilesButtonBlockProps } from './ExportFilesButtonBlock';

export const taskAggregatorBlockTypes = {
	clientTaskListBlock: 'urn:block:task-aggregator:client-task-list' as const,
	taskListBlock: 'urn:block:task-aggregator:task-list' as const,
	saveFilesToFolder: 'urn:block:task-aggregator:save-files-to-folder' as const,
	allFilesBlock: 'urn:block:task-aggregator:all-files' as const,
};

export interface VerticalizationParams {
	verticalContextKey?: string;
}

export interface TaskListBlockParams extends VerticalizationParams {
	containerRID: StringOrStringArray;
	kind?: TaskCategory;
	hideHeader?: boolean;
	/** Defaults to known types */
	taskResourceTypes?: TaskType[];
	emptyTitle?: string;
	emptySecondaryMessage?: string;
}

export interface ClientTaskListBlockParams extends VerticalizationParams {
	containerRID: string;
	kind?: TaskCategory;
	/** Defaults to known types */
	taskResourceTypes?: TaskType[];
}

export type ExtensionProps = {
	piletApi: PiletApi;
};

const LazyClientTaskListBlock = React.lazy(() => import('./ClientTaskListBlock'));
export const AsyncClientTaskListBlock: React.FC<
	ExtensionProps & ClientTaskListBlockParams & BlockParams
> = props => (
	<React.Suspense fallback={<Spin />}>
		<LazyClientTaskListBlock {...props} />
	</React.Suspense>
);

const LazyTaskListBlock = React.lazy(() => import('./TaskListBlock'));
export const AsyncTaskListBlock: React.FC<
	ExtensionProps & TaskListBlockParams & BlockParams
> = props => (
	<React.Suspense fallback={<Skeleton />}>
		<LazyTaskListBlock {...props} />
	</React.Suspense>
);

const LazyExportFilesBlock = React.lazy(() => import('./ExportFilesButtonBlock'));
export const AsyncExportFilesBlock: React.FC<
	ExtensionProps & ExportFilesButtonBlockProps & BlockParams
> = props => (
	<React.Suspense fallback={<Skeleton />}>
		<LazyExportFilesBlock {...props} />
	</React.Suspense>
);

const LazyAllFilesBlock = React.lazy(() => import('./AllFilesBlock'));
export const AsyncAllFilesBlock: React.FC<ExtensionProps & BlockParams> = props => (
	<React.Suspense fallback={<Skeleton />}>
		<LazyAllFilesBlock {...props} />
	</React.Suspense>
);
