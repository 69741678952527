import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';

export const taskAggregatorModals = {
	selectFolder: 'urn:sfmodal:task-aggregator:select-folder' as const,
};

export interface SelectFolderModalParams {
	flowRID: string;
	projectRID: string;
	notifyFilesSavedSuccess: () => void;
	notifyFilesSavedError: () => void;
	notifyErrorLoadingFolders: () => void;
}

const LazySelectFolderModal = lazy(() => import('./SelectFolderModal'));
export const AsyncSelectFolderModal: React.FC<any> = props => (
	<Suspense fallback={<Spin />}>
		<LazySelectFolderModal {...props} />
	</Suspense>
);
