import React from 'react';
import { Spin } from 'antd';
import { ExtensionProps } from '../SolutionViewEngine/Blocks';

const LazyBlockDevelopmentPage = React.lazy(() => import('./BlockDevelopmentPage'));
export const AsyncBlockDevelopmentPage: React.FC<ExtensionProps> = props => (
	<React.Suspense fallback={<Spin />}>
		<LazyBlockDevelopmentPage {...props} />
	</React.Suspense>
);
