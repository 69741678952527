import { TaskAggregatorNetworkError } from './TaskAggregatorNetworkError';
import {
	PagedResponse,
	PagingOptions,
	SortOptions,
	TaskDto,
	TaskFilterOptions,
} from './types';

interface Configuration {
	origin: string;
	apiPath: string;
}

const configuration: Configuration = {
	origin: window.origin,
	apiPath: '/io/taskaggregator/v1/',
};

export function configureHttp(configure: (current: Configuration) => void) {
	configure(configuration);
}

async function createRequest(path: string, init: RequestInit = {}) {
	const url = `${configuration.origin}${configuration.apiPath}${path}`;
	init.headers = { ...init.headers, 'X-BFF-CSRF': 'true' };
	return new Request(url, init);
}

export const TaskAggregatorHttp = {
	async getAggregatedTasks(
		taskFilterOptions: TaskFilterOptions,
		pagingOptions: PagingOptions,
		sortOptions?: SortOptions
	) {
		const searchParams = new URLSearchParams();
		taskFilterOptions.includedTypes?.forEach(type => {
			searchParams.append('type', type);
		});

		if (typeof taskFilterOptions.containerRIDs === 'string') {
			searchParams.append('containerRID', taskFilterOptions.containerRIDs);
		} else {
			taskFilterOptions.containerRIDs.forEach(cid =>
				searchParams.append('containerRID', cid)
			);
		}

		if (taskFilterOptions.assignedUserRID) {
			searchParams.append('assignedUserRID', taskFilterOptions.assignedUserRID);
		}

		if (taskFilterOptions.kind) {
			searchParams.append('kind', taskFilterOptions.kind);
		}

		if (sortOptions?.sortField) {
			searchParams.append('sort', sortOptions?.sortField);
		}

		if (sortOptions?.sortDirection) {
			searchParams.append('direction', sortOptions?.sortDirection);
		}

		if (pagingOptions.paging?.take) {
			searchParams.append('Take', pagingOptions.paging.take.toString());
		}

		if (pagingOptions.paging?.continuationToken) {
			searchParams.append('ContinuationToken', pagingOptions.paging.continuationToken);
		}

		const request = await createRequest(`tasks?${searchParams}`, { method: 'GET' });
		const response = await fetchWithError(request, 'Unable to get aggregated tasks');
		const text = await response.text();
		return JSON.parse(text, dateReviver) as PagedResponse<TaskDto>;
	},
};

async function fetchWithError(request: Request, errorMessage: string) {
	const response = await fetch(request);
	if (response.status > 399) {
		const text = await response.text();
		throw text
			? JSON.parse(text)
			: new TaskAggregatorNetworkError(
					`${errorMessage} with status code ${response.status}`,
					response
			  );
	}

	return response;
}

function dateReviver(_key: string, value: string) {
	if (typeof value === 'string') {
		if (
			/^\d{4}-[01]\d-[0-3]\d(T[0-2]\d:[0-5]\d:[0-5]\d(\.\d*)?(Z|[+-]\d{2}:\d{2})?)?$/.test(
				value
			)
		) {
			return new Date(value);
		}
	}

	return value;
}
